<template>
	<div class="pt-4" :class="[withSearch || $slots.default ? 'pb-20' : 'pb-12 sm:pb-20', {'px-8 sm:px-0': !fluid}]">
		<div class="w-full flex justify-between items-center relative min-h-40">
			<h2 v-if="title" class="text-md sm:text-lg md:text-xl mb-0" :class="{'ml-8': !fluid}">{{ title }}</h2>
			<span v-else></span>

			<lazy-form-input v-if="withSearch" name="search"
							 class="animated-width-input absolute sm:relative right-0 sm:w-280" :class="focusSearch ? 'w-full' : 'w-120'"
							 v-model="searchValue"
							 @focus="focusSearch=true"
							 @blur="focusSearch=false"
							 hide-details
							 clearable
							 :icon="icons.search"
							 inverted
							 :placeholder="$t('placeholder.search')"/>
			<slot/>
		</div>
	</div>
</template>

<script>
import {searchIcon} from "@spoferan/nuxt-spoferan/icons";

export default {
	name: "TitleBar",

	emits: ['update:modelValue'],

	props: {
		title: {
			type: String,
			default: null
		},

		modelValue: {
			type: String,
			default: null
		},

		withSearch: {
			type: Boolean,
			default: false
		},

		fluid: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			searchValue: this.modelValue,
			focusSearch: false,
			icons: {
				search: searchIcon,
			}
		}
	},

	watch: {
		modelValue(modelValue) {
			this.searchValue = modelValue;
		},

		searchValue(searchValue) {
			this.$emit('update:modelValue', searchValue)
		}
	}
}
</script>
<style lang="scss">
.animated-width-input {
	-webkit-transition: width 0.3s ease-in-out;
	-moz-transition: width 0.3s ease-in-out;
	-o-transition: width 0.3s ease-in-out;
	transition: width 0.3s ease-in-out;
}
</style>